/* ===================================
    About
====================================== */

/*
    Theme Name: Herox
    Theme URI:
    Author: Themes Industry
    Author URI:
    Description: One Page Parallax Template
    Tags: one page, multipurpose, parallax, creative, html5

 */

/* ===================================
    Table of Content
====================================== */


/*  - Fonts
    - General
    - Fonts
    - Social Icon Hover
    - Header And Navigation
    - Slider
    - Feature
    - Team
    - Portfolio
    - Price
    - Parallax
    - Testimonial
    - Client
    - Blog
    - Contact
    - Map
    - Footer
    - Loader
    - Media Queries   */


/* ===================================
    Google font
====================================== */

@import url('https://fonts.googleapis.com/css?family=Exo:300,400,500,600,700,800,900');


.exo-font{font-family: 'Exo', sans-serif;}


/* ===================================
    General
====================================== */

body {font-family: 'Exo', sans-serif;font-size: 15px;color: #666666;font-weight:500;line-height: 26px;letter-spacing: 0.3px;overflow-x: hidden;-webkit-font-smoothing: antialiased;}
a {color:inherit;}
::-webkit-scrollbar-track {background-color: #e9edf0;border-left: 1px solid #ededed;}
::-webkit-scrollbar {width: 7px;background-color: #ff6666;}
::-webkit-scrollbar-thumb {background:#ff6666;}
a:hover, a:active {color: #ff6666;text-decoration: none;}
a:focus, a:active, button:focus, button:active, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {outline: none;text-decoration: none}
a, *::before, *::after, img, span, input, button, .navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next {transition: ease-in-out 0.2s;-webkit-transition: ease-in-out 0.2s;-moz-transition: ease-in-out 0.2s;-ms-transition: ease-in-out 0.2s;-o-transition: ease-in-out 0.2s;}
ul{list-style: none;padding: 0;}
:focus{outline: none;}
img {max-width: 100%}
section,.section-padding {position: relative;padding: 7.5rem 0;overflow: hidden;}
.section-top-margin{margin-top: 7.5rem;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {line-height: 1;text-transform: uppercase}
.btn.focus, .btn:focus{box-shadow: none;}

/* transition none */

.no-transition *{transition-timing-function:initial; -moz-transition-timing-function:initial; -webkit-transition-timing-function:initial; -o-transition-timing-function:initial; -ms-transition-timing-function:initial; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s;}


/* opacity */
.opacity-full{opacity: 1;}
.opacity-3{opacity: .3;}
.opacity-4{opacity: .4;}
.opacity-5{opacity: .5;}
.opacity-6{opacity: .6;}
.opacity-7{opacity: .7;}
.opacity-8{opacity: .8;}

/*font-weight*/
.font-weight-500{font-weight: 500;}
.font-weight-600{font-weight: 600;}

/* button */
.btn {
    display: inline-block;
    letter-spacing: .5px;
    line-height: inherit;
    border-radius: 0;
    text-transform: capitalize;
    /* width: auto; */
    font-weight: 600;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    -webkit-transition-property: color;
    -o-transition-property: color;
    -moz-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
}

.btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255,0.2);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    -moz-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform, -o-transform;
    -moz-transition-property: transform, -moz-transform;
    transition-property: transform, -webkit-transform, -moz-transform, -o-transform;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.btn:hover,.btn:focus, .btn:active{color: #FFFFFF;}
.btn:hover:before, .btn:focus:before, .btn:active:before {-webkit-transform: scaleX(1);-ms-transform: scaleX(1);-o-transform: scaleX(1);-moz-transform: scaleX(1);transform: scaleX(1);}

/* btn size */
.btn-medium {font-size: 12px;padding: 6px 25px;}
.btn-small {font-size: 12px;padding: 2px 18px;}
.btn-large {font-size: 14px;padding: 9px 34px;line-height: 25px;}
.btn-rounded {border-radius: 50px;}

/* btn style */

.btn-pink {background: #ff6666;color: #FFFFFF;border: 2px solid #ff6666;}
.btn-gray{background: #666666;color: #FFFFFF;border: 2px solid #666666;}
.btn-white{background: #FFFFFF;color: #666666;border: 2px solid #FFFFFF;}
.btn-transparent-white{color: #FFFFFF;background:transparent;border: 2px solid #FFFFFF;}
.btn-transparent-gray{color: #666666;background:transparent;border: 2px solid #666666;}
.btn-transparent-pink{color: #ff6666;background:transparent;border: 2px solid #ff6666;}

/* btn hover */
.btn-white:hover{color: #FFFFFF;background:transparent;}
.btn-white:before{display: none;}
.btn-transparent-white:hover,.btn-transparent-white:focus{color: #666666;;}
.btn-transparent-gray:hover,.btn-transparent-gray:focus{color: #FFFFFF;;}
.btn-transparent-pink:hover,.btn-transparent-pink:focus{color: #FFFFFF;;}

.btn-transparent-white:before{background: #FFFFFF;}
.btn-transparent-gray:before{background: #666666;}
.btn-transparent-pink:before{background: #ff6666;}


/* z index */
.z-index-1{z-index: 1;}
.z-index-2{z-index: 2;}
.z-index-2{z-index: 3;}
.z-index-2{z-index: 4;}
.z-index-2{z-index: 5;}


/* overlay */
.bg-overlay{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}

/* backround color */

.bg-black{
    background: #000000;}
.bg-dark {background-color: #1d1d21 !important;}


/* text-color */
.main-color{color:#ff6666;}


/* ===================================
   Social Icon Hover
====================================== */

/*icon bg bover */
.facebook-bg-hvr:hover{background:#4267B2 !important;color: #fff !important;border: 1px solid #4267B2 !important;}
.twitter-bg-hvr:hover{background:#1DA1F2 !important;color: #fff !important;border: 1px solid #1DA1F2 !important;}
.instagram-bg-hvr:hover{background:#C32AA3 !important;color: #fff !important;border: 1px solid #C32AA3 !important;}
.linkedin-bg-hvr:hover{background:#0077B5 !important;color: #fff !important;border: 1px solid #0077B5 !important;}
.pinterest-bg-hvr:hover{background:#BD081C !important;color: #fff !important;border: 1px solid #BD081C !important;}
.google-bg-hvr:hover{background:#DB4437 !important;color: #fff !important;border: 1px solid #DB4437 !important;}

/*icon text bover */
.facebook-text-hvr:hover{color:#4267B2 !important;}
.twitter-text-hvr:hover{color:#1DA1F2 !important;}
.instagram-text-hvr:hover{color:#C32AA3 !important;}
.linkedin-text-hvr:hover{color:#0077B5 !important;}
.pinterest-text-hvr:hover{color:#BD081C !important;}
.google-text-hvr:hover{color:#DB4437 !important;}


/* ===================================
    Header & Navigation
====================================== */

header {
    width: 100%;
    z-index: 99;
}

.top-header-area {
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    top: 0;
    z-index: 100;
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-bottom:rgba(255, 255, 255, 0.18);
    transition: ease-in-out 0.3s;
    -webkit-transition: ease-in-out 0.3s;
    -moz-transition: ease-in-out 0.3s;
    -ms-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
}
.header-appear .top-header-area{
    display: none;
}


/* logo change */

header .logo .logo-dark, header .logo .logo-light {visibility: hidden; opacity: 0; width: 0; transition-duration: 0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header .logo .logo-dark.default, header .logo .logo-light.default {visibility: visible; opacity: 1; width: auto; transition-duration:0.5s;  -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header.header-appear nav .logo .logo-light, header.header-appear nav.header-dark .logo .logo-light {visibility: visible;  opacity: 1; width: auto; transition-duration: 0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header.header-appear nav .logo .logo-dark, header.header-appear nav.header-dark .logo .logo-dark {visibility: hidden; opacity: 0; width: 0; transition-duration:0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header.header-appear nav .logo .logo-dark, header.header-appear nav.header-light .logo .logo-dark {visibility: visible;  opacity: 1; width: auto; transition-duration:0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header.header-appear nav .logo .logo-light, header.header-appear nav.header-light .logo .logo-light {visibility: hidden; opacity: 0; width: 0; transition-duration:0.5s; -webkit-transition-duration: 0.5s; -moz-transition-duration: 0.5s; -ms-transition-duration: 0.5s; -o-transition-duration: 0.5s;}
header a.logo {display: inline-block; vertical-align: middle;line-height: 0}
header a.logo img {max-height:35px; max-width: 200px;vertical-align: sub;}

/* logo top center navigation */

.navbar.top-center-logo{
    position: absolute;
    width: 100%;
    z-index: 99;
    padding: 0;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;

}

.navbar.top-center-logo .navbar-nav{
    position: relative;
    top: 150px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}
.header-appear .navbar.top-center-logo .navbar-nav{
    top: 0;
}

.header-appear .navbar.top-center-logo{
    height: 50px;
    position: fixed;
    top: 0;
    background: #FFFFFF;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

@-webkit-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@-o-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
    }
}
@keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        -o-transform: none;
        transform: none;
    }
}

.navbar.top-center-logo .navbar-nav{
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

header .top-center-logo a.logo{
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
header.header-appear .top-center-logo a.logo{
    top: 11px;
}

.top-center-logo .navbar-nav .nav-link{
    position: relative;
    overflow: hidden;
    margin-right: 2.6rem;
    margin-left: 2.6rem;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    text-transform: uppercase;
    -webkit-transition: all .1s ease,color .3s ease;
    -moz-transition: all .1s ease,color .3s ease;
    -ms-transition: all .1s ease,color .3s ease;
    -o-transition: all .1s ease,color .3s ease;
    transition: all .1s ease,color .3s ease;
}

.header-appear .top-center-logo .navbar-nav .nav-link{
    margin-right: 1.6rem;
    margin-left: 1.6rem;
    color: #1b1e21;
}
.top-center-logo .navbar-nav .nav-link:hover, .header-appear .top-center-logo .navbar-nav .nav-link:hover{
    color: #ff6666;
}

.nav-line .navbar-nav .nav-link.active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    visibility: visible;
    opacity: 1;
}

.nav-line .navbar-nav .nav-link:before {
    position: relative;
    content: '';
    width: 100%;
    max-width: 60%;
    margin: 0 auto;
    display: table;
    height: 3px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    bottom: -33px;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    background: #ff6666;
    visibility: hidden;
    opacity: 0;
}

.navbar-toggler {
    position: relative;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.navbar-toggler span {
    display: block;
    background: #fff;
    height: 2px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1;
}
.header-appear .navbar-toggler span {
    background:#666666;
}
.navbar-toggler span:nth-child(1), .navbar-toggler span:nth-child(3) {
    -webkit-transition: transform .35s ease-in-out;
    -o-transition: transform .35s ease-in-out;
    -webkit-transition: -webkit-transform .35s ease-in-out;
    transition: -webkit-transform .35s ease-in-out;
    -o-transition: -o-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out, -o-transform .35s ease-in-out;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 3px;
    visibility: hidden;
    background-color: transparent;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}



/* logo top left navigation */

.navbar.top-left-logo{
    position: absolute;
    width: 100%;
    z-index: 99;
    padding: 0;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;

}

.navbar.top-left-logo .navbar-nav{
    position: relative;
    top: 150px;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
.header-appear .navbar.top-left-logo .navbar-nav{
    top:0;
}

.navbar.top-left-logo .container{
    position: relative;
}

.header-appear .navbar.top-left-logo{
    height: 50px;
    position: fixed;
    background: #FFFFFF;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

.navbar.top-left-logo .navbar-nav{
    border-top: 1px solid rgba(255, 255, 255, 0.18);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

header .top-left-logo a.logo{
    position: absolute;
    top: 90px;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}

header .top-left-logo .header-social{
    position: absolute;
    top:90px;
    right: 35px;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;

}
header .top-left-logo .header-social .header-search{
    color: #FFFFFF;
    line-height: 30px;
    display: inline-block;
    text-align: center;
    margin: 0 0 0 25px;
    border-left: 1px solid #ededed;
    font-size: 17px;
    cursor: pointer;
}
header .top-left-logo .header-social .header-search input{
    position: relative;
    width: 40px;
    background: transparent;
    color: #FFFFFF;
    padding-left: 35px;
    border: none;
    opacity: 0;
    cursor: pointer;
    -webkit-transition: width .3s linear;
    -moz-transition: width .3s linear;
    -ms-transition: width .3s linear;
    -o-transition: width .3s linear;
    transition: width .3s linear;
}

header .top-left-logo .header-social .header-search.search-open input{
    cursor:text;
    opacity: 1;
    padding-right: 20px;
    width: 200px;
}
.header-search.search-open input::placeholder {color:#FFFFFF;opacity: 1;}
.header-search.search-open input:-ms-input-placeholder {color:#FFFFFF;}
.header-search.search-open input::-ms-input-placeholder {color:#FFFFFF;}

header .top-left-logo .header-social .header-search i{
    position: absolute;
    top: 6px;
    right: -20px;
    z-index: 2;
    color: #FFFFFF;
;
}
header .top-left-logo .header-social .header-search.search-open .ti-search:before {
    content: "\e646";
}

header.header-appear .top-left-logo .header-social{
    top: -30px;
}

header .top-left-logo .header-social a{
    color: #FFFFFF;
    height: 30px;
    width: 30px;
    line-height: 32px;
    border: 1px solid transparent;
    display: inline-block;
    text-align: center;
    margin: 0 5px;
    border-radius: 50px;
}


header.header-appear .top-left-logo a.logo{
    position: relative;
    top: 0;
}

.top-left-logo .navbar-nav .nav-link{
    position: relative;
    overflow: hidden;
    padding-right: 4.1rem;
    padding-left: 1.6rem;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    text-transform: uppercase;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}

.top-left-logo .navbar-nav .nav-link:hover,.header-appear .top-left-logo .navbar-nav .nav-link:hover{
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -ms-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    color:#ff6666;
}

.top-left-logo .navbar-nav .nav-item:last-child .nav-link{
    padding-right: .2rem;
}

.header-appear .top-left-logo .navbar-nav .nav-link{
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    color: #1b1e21;
}

.nav-line-left .navbar-nav .nav-link.active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    visibility: visible;
    opacity: 1;
}

.nav-line-left .navbar-nav .nav-link:before {
    position: absolute;
    content: '';
    width: 3px;
    margin: 0 auto;
    display: table;
    height: 17px;
    left: 0;
    top: 12px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    background: #ff6666;
    visibility: hidden;
    opacity: 0;
}


/* nav top default */

.navbar-top-default{
    position: absolute;
    top: 0;
    z-index: 99;
    height: 90px;
    width: 100%;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
.navbar-top-default .navbar-nav .nav-link {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    color: #FFFFFF;
    font-weight: 400;
}

.navbar-top-default .navbar-nav .nav-link:hover,.header-appear .navbar-top-default .navbar-nav .nav-link:hover{
    color: #ff6666;;
}

.navbar-top-default.dark-navbar .navbar-nav .nav-link{
    color: #1b1e21;
}

.header-appear .navbar-top-default {
    height: 50px;
    position: fixed;
    background: #FFFFFF;
    -webkit-animation-name: animationFade;
    -o-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}
.header-appear .navbar-top-default .navbar-nav .nav-link {
    color: #1b1e21;
    font-weight: 400;
}
header .navbar-top-default .header-social a {
    color: #FFFFFF;
    height: 20px;
    width: 20px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    margin: 0 5px;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}

.header-appear .navbar-top-default .header-social a{
    color: #1b1e21;
}
header .navbar-top-default.dark-navbar .header-social a{
    color: #1b1e21;
}

/* nav bottom  */

.navbar-top-default.navbar-bottom{
    position: absolute;
    top: auto;
    bottom: 0;
    margin: 0;
}
.header-appear .navbar-top-default.navbar-bottom {
    height: 50px;
    position: absolute;
    background: #FFFFFF;
    -webkit-animation-name:fadeIn;
    -o-animation-name:fadeIn;
    animation-name:fadeIn;
    -webkit-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

.navbar-top-default.navbar-bottom.navbar-bottom-top{
    position: fixed;
    top: 0;
    bottom: auto;
}

/* center logo */

.navbar-top-default.center-logo .logo{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.navbar-top-default.center-logo .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

/* ===================================
   Slider
====================================== */

.slider-social{
    display: inline-block;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    z-index: 25;
    left: 40px;
}

.slider-social a{
    display: block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    margin: 5px;
    font-size: 16px;
    color: #FFFFFF;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    -ms-transition: all 100ms ease-in;
    -o-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
}

.dark-slider .slider-social a{
    color: #666666;
}

.scroll-down {
    position: absolute;
    bottom: 30px;
    z-index: 30;
    right: 45px;
}

.scroll-down .triangle-down{
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ff6666;;
}

.rev_slider .tp-parallax-wrap p{
    font-size: 15px !important;
    line-height: 20px !important;
}

.single-cover {
    height: 100vh;
    width: 100%;
}
#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
}

.single-cover .center-item{
    margin-top: 10rem;
}

.single-cover h2{
    font-size: 4rem;
}
.single-cover p{
    letter-spacing: 2px;
}

.rev-gradient{
    height: 100vh;
    width: 100%;
}


/* ===================================
   Features
====================================== */

.feature-box h4{
    line-height: 1.4;
}

.feature-icon{
    font-size: 50px;
    color: #cdcdcd;
}

.hr-line{
    width: 30px;
    height: 1px;
    background: #ff6666;
    display: block;
    margin: 0 auto;
}

.circle-top:after{
    content: url("../images/circle-one.png");
    position: absolute;
    top: -120px;
    right: 50px;
}

.circle-left:before{
    content: url("../images/circle-two.png");
    position: absolute;
    top:0;
    left:-400px;
}


/* feature two */

.right-img-1{
    background: url(../images/half_section_right_img1.jpg);
}

.half-section-right{
    right: 0;
    top: 0px;
    left: auto;
    height: 100%;
    width: 50%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
}

.feature-two-icon a{
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 32px;
    text-align: center;
    margin-right: 3px;
    font-size: 13px;
    border: 1px solid;
    border-radius: 50px;
}

.prog-item{
    margin-bottom: 1.3rem;
}
.prog-item:last-child{
    margin-bottom:0;
}

.prog-item .skills-progress {
    width: 100%;
    height: 5px;
    position: relative;
    background: rgb(238, 238, 238);
}
.prog-item .skills-progress span {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 10%;
    background:#ff6666;
    border-radius: 10px;
    transition: all 1s;
}
.prog-item .skills-progress span::after {
    content: attr(data-value);
    position: absolute;
    top: -30px;
    right: 0px;
    font-size: 13px;
    font-weight: 500;
}
.skills{
    text-align: left;
}
.skills p{
    margin-bottom: .2rem;
}

/* ===================================
   Team
====================================== */


.team-box input {
    position: absolute;
    display: none;
}
.team-box form ul{
    padding: 0 ;
    margin: 0;
}

.team-box .team-name{
    position: relative;
    display: block;
    width: 420px;
    transform: rotate(-90deg);
    top: 197px;
    left: -185px;
    text-align: left;
    padding-left: 25px;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.team-box label {
    position: relative;
    display: block;
    height:420px;
    width: 50px;
    z-index: 3;
    background: #ff6666;
    text-align: center;
    border-right: 1px solid #FFFFFF;
    border-left: 1px solid #FFFFFF;
    margin-bottom:0;
    float: left;
    overflow: hidden;
    -webkit-transition: width 1s ease, background 0.5s ease;
    -moz-transition: width 1s ease, background 0.5s ease;
    -o-transition: width 1s ease, background 0.5s ease;
    -ms-transition: width 1s ease, background 0.5s ease;
    transition: width 1s ease, background 0.5s ease;
}

.team-box #rad2 + .team-box label {
    background: #888;
    -webkit-transition: width 1s ease, background 0.5s ease;
    -moz-transition: width 1s ease, background 0.5s ease;
    -o-transition: width 1s ease, background 0.5s ease;
    -ms-transition: width 1s ease, background 0.5s ease;
    transition: width 1s ease, background 0.5s ease;
}

.team-box label:hover,.team-box #rad2 + .team-box label:hover {
    background: #e65151;
    color: #fff;
    cursor: pointer;
}

.team-box{
    position: relative;
    overflow: hidden;
    padding: 35px 0;
}

.team-box .accslide {
    display:block;
    height:420px;
    width: 0px;
    background: #fff;
    opacity: 0;
    overflow-x: hidden;
    float: left;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.team-box .accslide .team-about{
    padding: 40px;
    width: 579px;
    display: inline-block;
    float: left;
    background: #FFFFFF;
    z-index: 1;
}

.team-box .accslide .team-img{
    display: inline-block;
    width: 380px;
    float: right;
    position: absolute;
    margin-top: -35px;
}


.team-box .accslide .team-inner{
    width: 960px;
    background: #FFFFFF;
}
.team-box input[type="radio"]:checked+label ~ .accslide {
    width: 960px;
    opacity: 1;
}


/* team two */

.team-box-two-inner{
    max-width:700px;
    margin: 0 auto;
    position: relative;
}
.team-box-two-inner .team-two-about{
    position: relative;
    max-width: 395px;
    padding: 40px 80px 40px 40px;
    background: #f4f4f4;
}
.team-box-two-inner .team-two-about p{
    max-width: 280px;
 }
.team-two-social a{
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
}
.team-two-image{
    margin-left: -40px;
    position: relative;
}


.team-two .navPrev {
    position: absolute;
    top: 15%;
    left: 15px;
    z-index: 0
}
.team-two .navPrev span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}

.team-two .navPrev:hover span {
    width: 270px;
}

.team-two .navPrev span img {
    position: relative;
    margin: auto 0px;
    cursor: pointer;
    width: 270px;
}

.team-two .navNext {
    position: absolute;
    top: 15%;
    right: 15px;
    z-index: 2;
}

.team-two .navNext span {
    width: 270px;
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
}

.team-two .navNext span img {
    position: relative;
    margin: auto 0px;
    cursor: pointer;
     width: 270px;
}
.team-two .owl-team .owl-prev{
    position: absolute;
    top: 83px;
    height: 382px;
    margin: 0;
    padding: 0;
    width: 205px;
    background: transparent;
    border: 0;
    border-radius:0;
}
.team-two .owl-team .owl-prev{
    left: 0;
}

.team-two-left,.team-two-right{
    position: absolute;
    top: 0;
    height: 379px;
    width: 100%;
    cursor: pointer;
}
.team-two-right-nav, .team-two-left-nav{
    position: relative;
    max-width: 70px;
    height:378px;
    text-align: center;
    color: #FFFFFF;
    background: #ff6666;
}

.team-two-right-nav{
    float: right;
}
.team-two-left-nav{
    float: left;
}

.team-two-right-nav h4 {
    position: relative;
    width: 300px;
    display: block;
    transform: rotate(90deg);
    top: 140px;
    right: 115px;
    text-align: left;
    margin: 0;
    padding-left: 15px;
    text-transform: uppercase;
}

.team-two-left-nav i{
   position: relative;
    top: 12px;
    font-size: 20px;
    color: #FFFFFF;

}
.team-two-left-nav .team-verticle-line{
    position: relative;
    height: 80px;
    width: 2px !important;
    display: block !important;
    margin: 0 auto;
    background: #FFFFFF;
    top: 20px;
}

.team-two-left-nav h4 {
    position: relative;
    width: 300px;
    display: block;
    transform: rotate(-90deg);
    top:90px;
    right: 115px;
    text-align: left;
    margin: 0;
    padding-right: 15px;
    text-transform: uppercase;
}

.team-two-right-nav i{
    position: absolute;
    bottom: 15px;
    right: 23px;
    font-size: 20px;
    color: #FFFFFF;
}
.team-two-right-nav .team-verticle-line{
    height: 80px;
    width: 2px !important;
    display: inline-block !important;
    background: #FFFFFF;
    position: absolute;
    bottom: 55px;
    right: 33px;
}

.owl-team.owl-theme .owl-nav .owl-next{
    display: none;
}
.owl-team.owl-theme .owl-nav [class*=owl-]:hover{
    background: transparent;
}

/* team two animation */

.owl-team .owl-item.active .team-two-about{
    animation-name: fadeInRight;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    backface-visibility: hidden;
}
.owl-team .owl-item.active .team-two-image{
    animation-name: fadeInRight;
    animation-duration: 0.3s;
    animation-delay: 0.2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    backface-visibility: hidden;
}


/* team three */

.team-box-three,
.team-box-three ul li {
    display: inline-block;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.team-box-three {
    position: relative;
    overflow: hidden;
    max-width: 350px;
    margin: 0 auto;
}
.team-box-three h3{
    font-size: 1.5rem;
}
.team-box-three .team-content {
    padding: 30px 1.25rem;
    text-align: center;
    background: #FFFFFF;
}
.team-box-three .image{
    position: relative;
    overflow: hidden;
}
.team-box-three .overlay
{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.team-box-three:hover .overlay {
    opacity: 1;
    visibility: visible;
}
.team-box-three ul li {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

ul.social_icon{
    margin-bottom: 0;
}

ul.social_icon li a {
    display: block;
    font-size: 16px;
    height: 36px;
    line-height: 40px;
    text-align: center;
    border: 1px solid;
    width: 36px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #fff;
    border-color: #FFFFFF;
    color: #384141;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    -webkit-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;

}
ul.social_icon li a:hover{
    color: #FFFFFF;
}

.team-box-three ul li:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}
.team-box-three ul li:nth-child(2) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}
.team-box-three ul li:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}
.team-box-three ul li:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.team-box-three ul li:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}
.team-box-three ul li:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}
.team-box-three ul li:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}
.team-box-three:hover ul li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}


/*-------------------------------*/
/* Gallery Portfolio */
/*-------------------------------*/

.portfolio_top {
    position: relative;
    overflow: hidden;
}
#portfolio_top::before,
#portfolio_top::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    height: 90px;
    width: 100%;
}
#portfolio_top::before {
    top: 0;
}
#portfolio_top::after {
    bottom: 0;
}
.text_wrap {
    padding: 50px 2rem 0.5rem 2rem;
}
.itemshadow .cbp-item-wrapper {
    -webkit-box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
    box-shadow: 0 0 4px 1px rgba(101, 101, 101, 0.2);
    padding-bottom: 2px;
    padding-top: 5px;
}
.border-portfolio .cbp-item img {
    border: 20px solid #fff;
}
.bottom-text {
    display: table;
}
.bottom-text > .cells {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
}
.bottom-text p {
    font-size: 15px;
    margin-bottom: 0;
}
.bottom-text .port_head {
    font-weight: bold;
    font-size: 5.625rem;
    line-height: 1;
}

/*Overlays*/

.overlay{
    position: absolute;
    left: 20px; top: 25px;
    right: 20px; bottom: 22px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    -webkit-transition: all .5s ease-in .1s;
    -o-transition: all .5s ease-in .1s;
    transition: all .5s ease-in .1s;
}

.overlay:before{
    content: "";
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background:#ff6666;
    opacity: .7;
}

.cbp-item:hover .overlay,
.cbp-item:focus .overlay {
    opacity: 1; visibility: visible;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.cbp-item:hover .overlay{
    opacity: 1; visibility: visible;
}
.plus{
    display: inline-block;
    height: 70px;
    width: 70px;
    position: relative;
    overflow: hidden;
}
.overlay > h4,.overlay > p{
    position: relative;
}

.plus::before,
.plus::after {
    content: " ";
    position: absolute;
    background: #fff;
}
.plus::after {
    height: 2px;
    margin-top: -1px;
    top: 50%;
    left: 5px;
    right: 5px;
}
.plus::before {
    width: 2px;
    margin-left: -1px;
    left: 50%;
    top: 5px;
    bottom: 5px;
}
.overlay p {
    font-size: 13px;
}
.port_head {
   color: #FF6666;
}


.bottom-text > .cells {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
}

.center-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* portfolio three */

.gallery{
    width: 100% !important;
    height: 600px;
}

.portfolio-three .filtering .active {
    color: #ff6666;
}

.portfolio-three .filtering span {
    background-color: #fff;
    border-right: 3px solid #ECECEC;
    line-height: 18px;
    color: #888888;
    cursor: pointer;
    overflow: visible;
    padding: 0 17px;
    position: relative;
    display: inline-block;
    -webkit-transition: color .3s ease-in-out, border-color .3s ease-in-out;
    transition: color .3s ease-in-out, border-color .3s ease-in-out;
}

.portfolio-three .filtering span:last-child{
    border-right: none;
}

.portfolio-three .items {
    position: relative;
    padding: 0;
}
.portfolio-three .items {
    padding:0 5px;
}
.portfolio-three .items{
    margin-top: 10px;
}

.portfolio-three .item-img {
    position: relative;
    overflow: hidden;
}
.portfolio-three .item-img img{
    width: 100%;
}

.portfolio-three .item-img-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    color: #ffffff;
    opacity: 0;
    -webkit-transition: all .5s;
    transition: all .5s;
    z-index: 2;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
}
.portfolio-three .item-img-overlay:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff6666;
    opacity: .7;}

.valign {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.overlay-info{
    position: relative;
    width: 100%;
}
.overlay-info p{
    font-size: 13px;
}

.portfolio-three .item-img:hover .item-img-overlay {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.portfolio-three .item-img-overlay a {
    font-size: 30px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #FFF;
}

/* ===================================
    price
====================================== */


.price-item{
    padding:60px 30px;
    max-width: 350px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.price-item.center{
    margin-top: -23px;
    background: #ff6666;
    color: #FFFFFF;
}
.price-list li{
    padding: 10px 0;
    border-bottom: 1px solid #f5f5f5;
}

.price-item.center li{
    border-bottom: 1px solid rgba(243, 243, 243, 0.31);
}
.price-offer h6{
    background: #ffffff;
    color: #666;
    font-weight: 700;
    letter-spacing: 1.2px;
    position: absolute;
    width: 100%;
    left: -118px;
    top: 35px;
    line-height: 35px;
    transform: rotate(-45deg);
}
.price-item h2{
    font-size:3rem;
}


/* ===================================
    Parallax
====================================== */

.parallax{
    background-position: center center;
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
}

.video-parallax h2{
    font-size: 2.8rem;
}

/* Backhround Images */

.bg-1{ background: url(../images/bg-img1.jpg) no-repeat;}
.bg-2{ background: url(../images/bg-img2.png) no-repeat;}
.bg-3{ background: url(../images/bg-img3.jpg) no-repeat;}
.bg-4{ background: url(../images/bg-img4.jpg) no-repeat;}

.button-play-two {
    height: 50px;
    width: 50px;
    margin:15px 10px 20px 10px;
    line-height: 56px;
    padding-left: 3px;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 22px;
    top: 50%;
    left: 50%;
    z-index: 2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #ff6666;
    -webkit-animation: pulse_effect 1s infinite;
    -o-animation: pulse_effect 1s infinite;
    animation: pulse_effect 1s infinite;
    -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
}
.button-play-two i{
    color: #fff;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
.button-play-two:hover,.button-play-two:focus{
    color: #ff6666;
    background: #FFFFFF;
}
.button-play-two:hover i,.button-play-two:focus i{
    color: #ff6666;
}

.parallax-text{
    font-size: 3rem;
}


/* ===================================
    Testimonial
====================================== */

.testimonial-bg{
    background: url(../images/bg-img1.jpg) no-repeat;
}

.testimonial-quote {
    padding-top: 10px;
    margin-bottom: 25px;
}

.testimonial-quote p{
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.testimonial-quote h6{
    font-weight: 400;
    margin: 0;
}


#owl-thumbs.owl-dots .owl-dot {
    background: transparent;
    height: 65px;
    margin: 0;
    position: relative;
    width: 65px;
    -webkit-border-radius: 50px;
    display: inline-block;
    cursor: pointer;
    -moz-border-radius: 50px;
    border-radius: 50px;
    border: 5px solid rgba(255,255,255,.3);
    margin-left: -30px;
    opacity: .65;
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    transform: scale(.95);
}

#owl-thumbs.owl-dots .owl-dot > img {
    width: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
#owl-thumbs.owl-dots .owl-dot:first-child{
    margin-left: 0;
}
#owl-thumbs.owl-dots .owl-dot::after{
    display: none;
}
#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active{
    z-index: 5;
    opacity: 1;
    border: 5px solid rgba(255,255,255,.53);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/*testimonial two */

.testimonial-item .testimonial-quote {
    position: relative;
    background: #666666;
    color: #fff;
    font-size: 20px;
    line-height: 35px;
    display: inline-block;
    text-align: center;
    height: 50px;
    width: 50px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    z-index: 1;
    bottom: -50px;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}
.testimonial-text {
    background: #f6f6f6;
    padding: 4.25rem 2.5rem;
    border: 1px solid #f1f1f1;
}

.testimonial-white .testimonial-text{
    background: #FFFFFF;
}

.testimonial-photo {
    height: 100px;
    width: 100px;
    margin: -50px auto 0 auto;
}
.testimonial-photo > img {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.testimonial-item .text-small{
    font-size: .8rem;
}

.testimonial-item{
    cursor: pointer;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
}

.owl-testimonial .owl-item.center .testimonial-item .testimonial-quote{
    background: #ff6666;
}
.owl-testimonial .owl-item.center .testimonial-item .text-small{
    color:#ff6666;;
}
.owl-testimonial .owl-dots .owl-dot{
 margin-top: 5rem;
}

.owl-testimonial .owl-dots .owl-dot span {
    height: 12px !important;
    width: 12px;
    background: transparent;
    margin: 2px 3px;
    position: relative;
    border: 1px solid #ff6666;
    display: inline-block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
}
.owl-testimonial .owl-dots .owl-dot.active span,.owl-testimonial .owl-dots .owl-dot:hover span {
    background: #ff6666;
}




/* video section */

.button-play {
    height: 70px;
    width: 70px;
    line-height: 77px;
    padding-left: 7px;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 30px;
    top: 50%;
    left: 50%;
    z-index: 2;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    background: #ff6666;
    -webkit-animation: pulse_effect 1s infinite;
    -o-animation: pulse_effect 1s infinite;
    animation: pulse_effect 1s infinite;
    -webkit-box-shadow:0 0 0 0 rgb(255, 255, 255);
    box-shadow:0 0 0 0 rgb(255, 255, 255);
}
.button-play i{
    color: #fff;
    -webkit-transition: all .1s ease;
    -moz-transition: all .1s ease;
    -ms-transition: all .1s ease;
    -o-transition: all .1s ease;
    transition: all .1s ease;
}
.button-play:hover,.button-play:focus{
    color: #ff6666;
    background: #FFFFFF;
}
.button-play:hover i,.button-play:focus i{
    color: #ff6666;
}

@-webkit-keyframes pulse_effect {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
    }
}
@-o-keyframes pulse_effect {
    0% {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
    }
    25% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
    }
    70% {
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
    }
}
@keyframes pulse_effect {
    0% {
        -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.9);
    }
    25% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.6);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.3);
    }
    100% {
        -webkit-box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
    }
}

/* ===================================
    Client
====================================== */

.our-client .item {
    text-align: center;
}
.our-client.item,
.our-client .logo-item {
    position: relative;
}
.our-client .logo-item {
    width: 160px;
    height: 35px;
    line-height: 35px;
    overflow: hidden;
}
.our-client .logo-item, .our-client  .logo-item > img{
    display: inline-block;
}
.logo-item > img {
    width: 98%;
    position: absolute;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    cursor: pointer;
}

/* ===================================
    Work
====================================== */

 .content-carousel {
     display: block;
     margin: 0 auto;
 }
.content-carousel .owl-stage-outer{
    max-width: 75%;
}
.content-carousel .owl-carousel {
    width:100%;
}
.content-carousel .owl-carousel div {
    width: 100%;
}
.content-carousel .owl-carousel .owl-dots {
    position: absolute;
    padding:0 .5%;
    top: -4px;
    right:0;
    width:100%;
    max-width: 25%;
    height: 100%;
}

.content-carousel .owl-carousel .owl-dot {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 46%;
    display: inline-block;
    margin: 1% 2%;
    height: 18.7% !important;
    position: relative;
    overflow: hidden;
}
.content-carousel .owl-carousel .owl-dot:after{
    content: "";
    display: inline-block;
    width: 150%;
    height: 80%;
    position: absolute;
    right: 32%;
    top: -75%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewY(-50deg);
    transition: all 0.5s ease;
}

.content-carousel .owl-carousel .owl-dot:hover{
    filter: brightness(60%);
}
.content-carousel .owl-carousel .owl-dots .owl-dot:hover:after{
    right: -55%;
    top: 120%;
}

.content-carousel .owl-carousel .owl-item img{
    width: 100%;
    max-width: 80%;
}


/* ===================================
    Blog
====================================== */

.blog-box h4 {
    position: relative;
    padding-left: 15px;
    border-left: 2px solid #ff6666;
    line-height: 35px;
}
.container-padding {
    padding-left: 5.625rem;
    padding-right: 5.625rem;
}

/* ===================================
    Contact
====================================== */


.contact h6{
    font-size: 1.1rem;
}
.our-address .pickus {
    font-size: 13px;
    text-transform: uppercase;
    color: #ff6666;
    font-weight: 700;
    letter-spacing: 1px;
    position: relative;
}
.our-address .pickus,
.our-address .pickus::after {
    display: inline-block;
}
.our-address .pickus::after {
    content: attr(data-text);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    color: #666666;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.our-address .pickus:hover::after {
    width: 100%;
}
.our-address .pickus:hover,
.our-address .pickus:focus {
    color: #666666;
}

/*Contact Form*/
.getin_form .form-control {
    border: none;
    border-bottom: 1px solid #a5a5a5;
    padding: 12px 0;
    background: #fff;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 44px;
    color: #a5a5a5;
    font-size: 14px;
    position: relative;
    -webkit-transition: border .9s ease;
    -o-transition: border .9s ease;
    transition: border .9s ease;
}
.getin_form textarea {
    min-height: 200px;
    resize: none;
}

.getin_form .form-control:focus {
    border-bottom: 1px solid #666666;
}
.border-form .form-control {
    border: 1px solid #dedada;
    padding-left: 15px;
    padding-right: 15px;
    -webkit-border-radius: 24px;
    border-radius: 24px;
}

.logincontainer {
    padding: 3.5rem 1.5rem;
}
.border-form .form-control:focus {
    border: 1px solid #666666;
}

/* Map */

.map-container {
    height: 450px;
    width: 100%;
    margin-bottom: -70px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

.logo-icon{
    padding: 20px 40px;
    background: #1d1d21;
    display: inline-block;
    position: relative;
    z-index: 2;
    max-width: 190px;
}

/* footer social */

.footer-padding{
    padding-top: 7.5rem;
    padding-bottom: 4rem;
}

.footer-social a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    display: inline-block;
    text-align: center;
}

/* scroll top */
.scroll-top-arrow, .scroll-top-arrow:focus {
    font-size: 16px;
    line-height: 49px;
    color: #fff;
    background: #ff6666;
    display: none;
    height: 45px;
    width: 45px;
    padding: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    text-decoration: none;
    z-index: 99;
    border-radius: 100%;
}
.scroll-top-arrow:hover{color: #FFFFFF}


/* ===================================
    Blog Page
====================================== */

.page-header i{
    font-size: 11px;
}


/* blog list */

.blog-list-item{
    margin-bottom: 4rem;
}

.blog-list-item:last-child{
    margin-bottom: 1rem;
}

.blog-pagination li{
    display: inline-block;
}
.blog-pagination li a{
    display: block;
    width: 20px;
    height: 30px;
    margin: 0 5px;
    line-height: 30px;
    text-align: center;
    color: #a0a0a0;
}

.blog-pagination li a i{
    font-size: 12px;
}
.blog-pagination li a:hover,.blog-pagination li.active a,.blog-pagination li a:focus{
   color: #ff6666;
}

.widget {
    padding:2rem;
    margin-bottom: 3rem;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

.widget:last-child{
    margin-bottom: 0;
}

.widget .search{
    width: 100%;
    padding: 5px 15px;
    border: 1px solid #e6e6e6;
    border-right: none;
}
.widget .search-btn{
    position: relative;
    width: 40px;
    line-height: 39px;
    text-align: center;
    border: none;
    color: #FFFFFF;
    background: #ff6666;
    cursor: pointer;
}

.recent-post {
    margin-bottom: .5rem;
}
.recent-post:last-child {
    margin-bottom:0;
}

.recent-post img{
    width: 80px;
    height: 75px;
    margin-right: 15px;
}

.recent-post .text a{
    line-height: 24px;
    margin-bottom: 5px;
    display: block;
    text-transform: uppercase;
}
.recent-post .text .date{
    font-size: 12px;
}

.blog-category li{
    text-transform: uppercase;
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 20px;
    font-size: .8rem;

}

.blog-tags li{
    display: inline-block;
    margin-bottom: 6px;
}
.blog-tags li a{
    padding: 6px 15px;
    background: #666666;;
    font-size: 13px;
    color: #FFFFFF;
}

.blog-tags li a:hover,.blog-tags li a:focus{
    background:#ff6666;
    color: #FFFFFF;
}


.hover-effect{
    position: relative;
    overflow: hidden;
}

.hover-effect::before {
    right: 0;
    opacity: 1;
    top: 0;
}
.hover-effect::before, .hover-effect::after {
    content: "";
    background: #fff;
    height: 0;
    width: 0;
    z-index: 1;
    position: absolute;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
}
.hover-effect::after {
    bottom: 0;
    opacity: .7;
    left: 0;
}
.hover-effect::before, .hover-effect::after {
    content: "";
    background: #fff;
    height: 0;
    width: 0;
    z-index: 1;
    position: absolute;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
}
.hover-effect:hover::after, .hover-effect:hover::before {
    height: 100%;
    opacity: 0;
    width: 100%;
}

/* blog detail */

.blog-detail-item blockquote{
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-style: italic;
    letter-spacing: 1px;
    font-weight: 500;
    padding-left: 15px;
    border-left: 5px solid #eee;
}

.blog-detail-item .blog-share{
    margin: 0;
    display: inline-block;
    float: right;
}
.blog-detail-item .blog-share li{
    display: inline-block;
}

.blog-detail-item .blog-share li a{
    display:block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid #ededed;
    text-align: center;
    color: #a9a9a9;
}

.blog-detail-tag{
    padding:15px 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    margin: 3rem 0;
    color: #969696;
}
.blog-detail-tag a{
    margin: 0 3px;
}
.blog-detail-tag .btn.btn-transparent-gray{
    color: #a6a6a6;
    border:1px solid #ededed;
}
.blog-detail-tag .btn.btn-transparent-gray:hover,.blog-detail-tag .btn.btn-transparent-gray:focus{
    color: #FFFFFF;
}

.blog-detail-nav{
    margin-top: 2rem;
    position: relative;
    display:flow-root;
}

.blog-detail-item .date{
    color: #a6a6a6;
}


.blog-comment {list-style: none; margin: 0; padding: 0;}
.blog-comment .avatar{width: 110px; margin-right: 20px}
.blog-comment .comment-text{vertical-align: top;}
.blog-comment .date{font-size: 12px; margin-bottom: .5rem; color: #b3aeae;}
.blog-comment li {padding: 50px 0; border-bottom: 1px solid #ededed; position: relative}
.blog-comment > li:first-child {padding-top: 0}
.blog-comment li:last-child {border-bottom: none; padding-bottom: 0}
.blog-comment li ul.child-comment {margin-left: 40px; list-style: none}
.blog-comment li ul.child-comment li {border: none; padding-bottom: 0; border-top: 1px solid #ededed; margin-top: 50px}


.comment-form .form-control{
    border-radius:0;
}

.comment-form .form-control:focus{
    border-color:#ced4da;
    outline: 0;
    box-shadow: none;
}
.comment-form textarea{
    margin-bottom: 1.2rem;
    resize: none;
}

/* ===================================
    Side Menu
====================================== */


.side-menu {
    width: 40%;
    position: fixed;
    right: 0;
    top: 0;
    background: #ff6666;
    z-index: 1032;
    height: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: -o-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease, -o-transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    overflow: hidden;
}
.side-menu.left {
    left: 0; right: auto;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}
.side-menu.before-side {
    width: 280px;
}
.side-menu.side-menu-active,
.side-menu.before-side{
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.pul-menu .side-menu.side-menu-active {
    visibility: visible; opacity: 1;
}
.side-menu .navbar-brand {
    margin: 0 0 2.5rem 0;
}

/*Side overlay*/
#close_side_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    display: none;
    z-index: 1031;
    opacity: 0.4;
}

/*side clode btn*/
.side-menu .btn-close {
    height: 33px;
    width: 33px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 15px;
    cursor: pointer;
}
.side-menu.before-side .btn-close{
    display: none;
}
.just-sidemenu #sidemenu_toggle {
    position: fixed;
    z-index: 999;
    /*background: #fff;*/
    right: 0;
    top: 16px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
}
.side-menu .btn-close::before, .side-menu .btn-close::after {
    position: absolute;
    left: 16px;
    content: ' ';
    height: 24px;
    width: 2px;
    background: #fff;
    top: 5px;
}
.side-menu .btn-close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.side-menu .btn-close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/*side open btn*/
.sidemenu_btn {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    width: 36px;
    padding: 6px;
    margin-right: 1rem;
    position: absolute;
    right: 15px;
}
.sidemenu_btn.left {
    left: 15px;
    right: auto;
}
.sidemenu_btn > span {
    height: 2px;
    width: 100%;
    background: #FFFFFF;
    display: block;
}

.dark-navbar .sidemenu_btn > span{
    background:#666666;
}

.header-appear .sidemenu_btn > span{
    background: #666666;
}

.sidemenu_btn > span:nth-child(2) {
    margin: 4px 0;
}

.side-menu .inner-wrapper {
    padding: 3.5rem 5rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.pul-menu.pushwrap .side-menu .inner-wrapper{
    padding: 3.5rem 2.5rem;
}
.side-menu .side-nav {
    margin-bottom: 30px;
    display: block;
}
.side-nav .navbar-nav .nav-item{
    display: block;
    margin: 10px 0; padding: 0 !important;
    opacity: 0;
    -webkit-transition: all 0.8s ease 500ms;
    -o-transition: all 0.8s ease 500ms;
    transition: all 0.8s ease 500ms;

    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
}
.side-nav .navbar-nav .nav-item:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}
.side-nav .navbar-nav .nav-item:nth-child(2){
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}
.side-nav .navbar-nav .nav-item:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}
.side-nav .navbar-nav .nav-item:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}
.side-nav .navbar-nav .nav-item:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}
.side-nav .navbar-nav .nav-item:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}
.side-nav .navbar-nav .nav-item:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}
.side-nav .navbar-nav .nav-item:nth-child(8) {
    -webkit-transition-delay: .8s;
    -o-transition-delay: .8s;
    transition-delay: .8s;
}
.side-nav .navbar-nav .nav-item:nth-child(9) {
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s;
}
.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}
.side-nav .navbar-nav .nav-link {
    display: inline-table;
    color: #fff;
    padding: 2px 0 3px 0 !important;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: normal;
    position: relative;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.side-nav .navbar-nav .nav-link::after{
    content: "";
    position: absolute;
    background: #fff;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: 0; left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after,
.side-nav .navbar-nav .nav-link.active::after {
    width: 100%;
}
.side-nav .navbar-nav .nav-link.active {
    background: transparent;
}
.side-menu p{
    font-size: 13px;
    margin-top: .5rem;
    margin-bottom: 0;
}

.social-icons-simple{
    margin-bottom: 0;
    margin-left: -10px;
}

.social-icons-simple li{
    display: inline-block;
}

.social-icons-simple li a {
    display: block;
    font-size: 16px;
    height: 36px;
    line-height: 38px;
    border: 1px solid transparent;
    text-align: center;
    width: 36px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}
.social-icons-simple li a:hover{
    color: #FFFFFF;
}

.modal-content{border-radius: 0}


/* ===================================
    Page Loader
====================================== */

.loader {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 1200;
    background: #FFFFFF;
}

.cssload-loader {
    width: 54px;
    height: 54px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    top: 50%;
}

.cssload-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}

.cssload-inner.cssload-one {
    left: 0%;
    top: 0%;
    animation: cssload-rotate-one 1.15s linear infinite;
    -o-animation: cssload-rotate-one 1.15s linear infinite;
    -ms-animation: cssload-rotate-one 1.15s linear infinite;
    -webkit-animation: cssload-rotate-one 1.15s linear infinite;
    -moz-animation: cssload-rotate-one 1.15s linear infinite;
    border-bottom: 5px solid #FF6666;
}

.cssload-inner.cssload-two {
    right: 0%;
    top: 0%;
    animation: cssload-rotate-two 1.15s linear infinite;
    -o-animation: cssload-rotate-two 1.15s linear infinite;
    -ms-animation: cssload-rotate-two 1.15s linear infinite;
    -webkit-animation: cssload-rotate-two 1.15s linear infinite;
    -moz-animation: cssload-rotate-two 1.15s linear infinite;
    border-right: 5px solid #FF6666;
}

.cssload-inner.cssload-three {
    right: 0%;
    bottom: 0%;
    animation: cssload-rotate-three 1.15s linear infinite;
    -o-animation: cssload-rotate-three 1.15s linear infinite;
    -ms-animation: cssload-rotate-three 1.15s linear infinite;
    -webkit-animation: cssload-rotate-three 1.15s linear infinite;
    -moz-animation: cssload-rotate-three 1.15s linear infinite;
    border-top: 5px solid #FF6666;
}

@keyframes cssload-rotate-one {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@-o-keyframes cssload-rotate-one {
    0% {
        -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@-webkit-keyframes cssload-rotate-one {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@-moz-keyframes cssload-rotate-one {
    0% {
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes cssload-rotate-two {
    0% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@-o-keyframes cssload-rotate-two {
    0% {
        -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@-webkit-keyframes cssload-rotate-two {
    0% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@-moz-keyframes cssload-rotate-two {
    0% {
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes cssload-rotate-three {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@-o-keyframes cssload-rotate-three {
    0% {
        -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@-webkit-keyframes cssload-rotate-three {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@-moz-keyframes cssload-rotate-three {
    0% {
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}





/* ===================================
    Media Queries
====================================== */




@media screen and (max-width: 1367px) and (min-width: 1199px) {
    #rev_slider_15_1 .tp-parallax-wrap{
        padding-left: 0 !important;
        left: 120px !important;
    }

    #rev_slider_15_1 .slider-button{
        margin-left: 30px !important;
    }
    .navbar-top-default.center-logo .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }

}

@media screen and (min-width: 1610px){

    .content-carousel .owl-carousel .owl-dot{
        height: 18.8% !important;
    }
    #rev_slider_15_1 .tp-parallax-wrap{
        padding-left: 50px !important;
    }
}

@media screen and (max-width: 1199px) {


    #rev_slider_15_1 .tp-parallax-wrap{
        padding-left: 6rem;
    }
    .tp-bullets.heroex2{
        left: 58% !important;
    }

    .navbar-top-default.center-logo .logo {
        position: relative;
        left: 0;
        transform: translateX(0);
    }

    .top-center-logo .navbar-nav .nav-link{
        margin-right: 1.9rem;
        margin-left: 1.9rem;
    }

    .top-left-logo .navbar-nav .nav-link {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .header-appear .top-center-logo .navbar-nav .nav-link {
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .team-box input[type="radio"]:checked+label ~ .accslide,.team-box .accslide .team-inner {
        width: 780px;}
    .team-box .accslide .team-about {
        padding: 30px;
        width: 500px;
    }

    .team-box .accslide .team-img {
        width: 300px;
        margin-top: 0px;
    }

}
@media screen and (max-width: 991px) {

    .single-cover h2{
      font-size:3rem;
    }

    .tp-bullets.heroex2{
      display: none !important;
    }

    .top-header-area{
        display: none;
    }
    header .top-center-logo a.logo {
        position: relative;
        top:auto;
        left: 0;
        transform: translateX(0);
        margin: 20px 0;
    }

    .header-appear .navbar.top-center-logo{
        height: auto;
    }

    header.header-appear .top-center-logo a.logo
    {
        top: 0;
        margin: 10px 0;
    }

    .navbar.top-center-logo .navbar-nav{
        top: auto;
        padding-left: 15px;
    }
    .navbar.top-center-logo .navbar-nav
    {
        border: none;
    }
    .top-center-logo .navbar-nav .nav-link,.header-appear .top-center-logo .navbar-nav .nav-link {
        margin-right:0;
        margin-left: 6px;
    }
    .nav-line .navbar-nav .nav-link:before{
        display: none;
    }

    /* nav top left */

    .navbar.top-left-logo .navbar-nav {
        top: 0;}

    header .top-left-logo a.logo {
        position: relative;
        top: auto;
        margin: 20px 0;
    }
    header .top-left-logo .header-social {
        position: relative;
        top: 0;
        right: 0;
        display: none;
    }
    .header-appear .navbar.top-left-logo {
        height: auto;
    }
    .navbar.top-center-logo .navbar-nav, .header-appear .top-left-logo .navbar-nav .nav-link{
        padding-left:0;
        padding-right:0;
    }
    .top-left-logo .navbar-nav .nav-link {
        padding-right:0;
        padding-left: 0;
    }
    .nav-line-left .navbar-nav .nav-link:before{
        display: none;
    }
    header.header-appear .top-left-logo a.logo {
        top: 0;
        margin: 10px 0;
    }
    .navbar.top-left-logo .navbar-nav {
        border-top:none;
        border-bottom:none;
    }
    .navNext,.navPrev{
        display: none;
    }

    .team-box input[type="radio"]:checked+label ~ .accslide, .team-box .accslide .team-inner {
        width: 540px;
    }
    .team-box .accslide .team-about {
        padding: 15px;
        width: 350px;
    }

    .owl-team .owl-item.active .team-two-about{
        animation-name:initial;
        animation-duration: 0.1s;
        animation-delay: 0.1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        backface-visibility: hidden;
    }
    .owl-team .owl-item.active .team-two-image{
        animation-name: initial;
        animation-duration: 0.1s;
        animation-delay: 0.1s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        backface-visibility: hidden;
    }
    #portfolio_top::before, #portfolio_top::after {
        background: #f8f9fa;
        height: auto;
    }

    /* side nav */

    .sidemenu_btn {
        position: relative;
        right: 0;
    }

    .text_wrap {
    padding:0;
    }
    .text_wrap .heading-title{
        padding-top: .8rem !important;
    }
    .bottom-text > .cells{
        width: 100%;
        padding-bottom: 3rem;
    }

    .price-item.center{
        margin-top: 0;
    }

}

@media screen and (max-width: 775px){
    #rev_slider_15_1 .tp-parallax-wrap {
        padding-left:0 !important;
    }
}

@media screen and (max-width: 767px) {

    .mb-xs-5{
        margin-bottom: 3rem!important;
    }
    .mb-xs-4{
        margin-bottom: 1.5rem!important;
    }
    .mb-xs-3{
        margin-bottom: 1.5rem!important;
    }

    .single-cover h2{
        font-size:2rem;
    }

    .blog-box h4{
        padding: 0;
        border-left: none;
    }

    .sm-container-full{
        max-width: 100%;
    }

    .team-box label{
        height: 50px;
        width: 100%;
        float: none;
        border-bottom: 2px solid #FFFFFF;
        border-top:none;
    }

    #rad1 label{
        margin-bottom: -2px;
    }

    .team-box .accslide {
        height:0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        border-left:1px solid #e8dcdc;
        border-right:1px solid #e8dcdc;
        border-bottom:none;
        border-top: none;
        -webkit-transition:height 1s linear;
        -moz-transition:height 1s linear;
        -o-transition:height 1s linear;
        -ms-transition:height 1s linear;
        transition:height 1s linear;
    }
    .team-box input[type="radio"]:checked+label ~ .accslide, .team-box .accslide .team-inner {
        width: 100%;
        height: 850px;
        opacity: 1;
        visibility: visible;
        overflow: hidden;
    }
    .team-box .accslide .team-about {
       display: block;
        width: 100%;
    }
    .team-box .accslide .team-img {
        width: 100%;
        margin-top: 15px;
        position: relative;
    }
    .team-img img{
        width: 100%;
    }
    .team-box .team-name {
        position: relative;
        text-align: center;
        line-height: 50px;
        top: auto;
        left: auto;
        transform: translateY(0);
        height: 50px;
        width: 100%;
        padding: 0;
        display:block;
    }

    .content-carousel .owl-carousel .owl-dots {
        position: relative;
        padding: 0.5%;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        margin-top: 5px;
    }
    .content-carousel .owl-carousel .owl-dot {
        max-width: 19%;
        margin: 0 .5%;
        height:100px !important;
        position: relative;
        overflow: hidden;
    }
    .content-carousel .owl-stage-outer {
        max-width:100%;
    }
    .content-carousel .owl-carousel .owl-item img {
        width: 100%;
        max-width: 98%;
        margin: 0 auto;
    }

    .heroex, .tp-tabs, .tp-thumbs{
        display: none !important;
    }
    .slider-social {
        display: block;
        top: auto;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
    .slider-social a {
        display: inline-block;
    }
    section, .section-padding {
        padding: 4rem 0;
    }
    .video-parallax h2 {
        font-size: 2rem;
    }
    header .top-center-logo a.logo{
        padding-left: 15px;
    }
    header .top-left-logo a.logo{
        padding-left: 15px;
    }

    .feature-box .h4,.feature-box h4 {
        font-size: 1.2rem;
    }
    .map-container {
        height: 350px;
    }

    .team-box{
        padding: 0;
    }

    /* side menu */

    .side-menu {
        width: 380px;}

    .bottom-text .port_head {
        font-size: 4rem;
     }
    .btn-large {
        font-size: 13px;
        padding: 6px 26px;
       }
    .circle-top:after, .circle-left:before{
        display: none;
    }
    .parallax-text {
        font-size: 2rem;
    }

    .page-title{
        padding: 4rem 0 1rem;
    }

}


@media screen and (max-width: 600px) {

    .content-carousel .owl-carousel .owl-dot {
        height: 60px !important;
        max-width: 18.2%;
        margin: 0 0.9%;
    }
    .team-box-two-inner .team-two-about {
        max-width: 100%;
        padding:50px 30px;
    }
    .team-two-image {
       margin-left:0;
    }
    .team-box-two-inner.d-flex {
        display: block !important;
    }
    .team-box-two-inner .team-two-about p {
        max-width: 100%;
    }
    .team-two-image {
        margin-left: 0;
        height: 430px;
    }
    .slider-text{
        font-size: 28px !important;
    }

    .page-content{
        padding-left:15px;
        padding-right: 15px;
    }

    .blog-detail-tag{
        text-align: center;
    }

    .blog-detail-item .blog-share {
        text-align: center;
        display: block;
        float: none;
        margin-top: .7rem;
    }

}