.heroex .tp-bullet.selected, .tp-bullet:hover {
    background:transparent;
}
.heroex .tp-bullet {
    width: auto;
    height: auto;
    position: relative;
    background:transparent;
    cursor: pointer;
    color: #FFFFFF;
    transition: 3s height;
    margin-bottom: -23px;
}

.dark-slider .heroex .tp-bullet{
    color: #666666;
}

.heroex .tp-bullet-number{
    position: relative;
    text-align: center;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.heroex .tp-bullet.selected:nth-child(4){
    padding-bottom: 4px;
    border-bottom: 2px solid;
}

.heroex .tp-bullet .tp-bullet-line{
    transform: scaleY(0);
    visibility: hidden;
    opacity: 0;
}
.heroex .tp-bullet.selected .tp-bullet-line{
    height: 120px;
    width: 2px;
    background: #FFFFFF;
    display:inline-block;
    transform: scaleY(1);
    -webkit-transition: transform 1.5s;
    -moz-transition: transform 1.5s;
    -ms-transition: transform 1.5s;
    -o-transition: transform 1.5s;
    transition: transform 1.5s;
    visibility: visible;
    opacity: 1;
}
.dark-slider .heroex .tp-bullet.selected .tp-bullet-line{
    background: #9b9b9b;
}

.heroex, .tp-tabs, .tp-thumbs{
    top: 43% !important;
}

.heroex .tp-bullet .tp-count{
    font-size: 14px;
    position: relative;
    bottom: 5px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.heroex .tp-count:before{
    content: '';
    height: 3px;
    width: 15px;
    transform: scaleX(0);
    display: block;
    position: absolute;
    background: #ff6666;
    top: 50%;
    right: -22px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.heroex .tp-bullet.selected .tp-count:before{
    transform: scaleX(1);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}





.heroex2 .tp-bullet.selected,.heroex2 .tp-bullet:hover {
    background:transparent;
}
.heroex2 .tp-bullet {
    width: 140px;
    height: 20px;
    background:transparent;
    cursor: pointer;
    color: #FFFFFF;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
.heroex2 .tp-bullet .tp-bullet-number{
    position: relative;
    text-align: left;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}
.heroex2 .tp-bullet .tp-bullet-line{
    height: 2px;
    width: 100%;
    display: block;
    background: #ffffff3b;
}
.heroex2 .tp-bullet .tp-bullet-line::before{
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background: #FFFFFF;
    opacity: 0;
    -webkit-transition: width 10s linear;
    -moz-transition: width 10s linear;
    -o-transition: width 10s linear;
    -ms-transition: width 10s linear;
    transition: width 10s linear;
}

.heroex2 .tp-bullet.selected .tp-bullet-line::before{
    opacity: 1;
    width: 100%;
}